import React from 'react'
import './Nav.css'
import {RiHomeLine} from 'react-icons/ri'
import {PiNewspaperLight} from 'react-icons/pi'
import {BsPerson} from 'react-icons/bs'
import {PiFileCodeLight} from 'react-icons/pi'
import {AiOutlineMail} from 'react-icons/ai'

const Nav = () => {
  return (
    <nav>
      <a href="#"><RiHomeLine/></a>
      <a href="#Projects"><PiFileCodeLight/></a>
      <a href="#Contact"><AiOutlineMail/></a>
    </nav>
  )
}

export default Nav