import React from 'react'
//import {RiSeparator} from 'react-icons/ri'
import {BiChevronRight} from 'react-icons/bi'
//import {PiDotOutlineThin} from 'react-icons/pi'
//import {CiSquareChevRight} from 'react-icons/ci'

function ExpProps(props)
{
    const {choice} = props;
    
    if(choice === "expSE")
        return expSE();
    else if(choice === "expIT")
        return expIT();
    else
        return expSA();
}

function expSE()
{
  return (
    <div className = "expSE">
        <h3>Software Engineering</h3>
        <div className = "expContent">
            <article className = "expDetails">          
                <h4><br/>Java</h4>
                <h5><BiChevronRight/> CVS Caremark</h5>
                <small className = "text-light">Application testing</small>
                <h5><BiChevronRight/> CVS Caremark</h5>
                <small className = "text-light">Release Management</small>
                <h5><BiChevronRight/>  Kota Tech</h5>
                <small className = "text-light">Desktop application dev</small>
            </article>
            <article className = "expDetails">
                <h4><br/>API</h4>
                <h5><BiChevronRight/> CVS Caremark</h5>
                <small className = "text-light">REST, JSON in SoapUI</small>
                <h5><BiChevronRight/> CVS Caremark</h5>
                <small className = "text-light">SOAP, WSDL in SoapUI</small>
            </article>
            <article className = "expDetails">
                <h4><br/>PowerShell</h4>
                <h5><BiChevronRight/>  Kota Tech</h5>
                <small className = "text-light">Powershell scripts for dental environments</small>
                <h5><BiChevronRight/>  Kota Tech</h5>
                <small className = "text-light">Bash and powershell scripts in Java app</small>
            </article>
                <article className = "expDetails">
                <h4><br/>SPL</h4>
                <h5><BiChevronRight/> CVS Caremark</h5>
                <small className = "text-light">Automated alerts through Splunk</small>
                <h5><BiChevronRight/> CVS Caremark</h5>
                <small className = "text-light">Query creation during releases for error detection</small>
            </article>
        </div>
    </div>
  );
}
function expIT()
{
  return (
    <div className = "expIT">
        <h3>Information Technology</h3>
        <div className = "expContent">
            <article className = "expDetails">
                <h4><br/>Hardware</h4>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">PC modifications and maintenance</small>
                <h5><BiChevronRight/> Augustana University</h5>
                <small className = "text-light">Desktop teardowns for maintenance</small>
            </article>
            <article className = "expDetails">
                <h4><br/>Software</h4>
                <h5><BiChevronRight/> CVS Caremark</h5>
                <small className = "text-light">Daily application testing</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Software troubleshooting, repair</small>
                <h5><BiChevronRight/> Augustana University</h5>
                <small className = "text-light">Campus-wide OS upgrades through custom ISO</small>
            </article>
                <article className = "expDetails">
                <h4><br/>Security</h4>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Event viewer user audits</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Zero-Day exploit mitigation</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">White-hat Spear Phishing</small>
            </article>
            <article className = "expDetails">
                <h4><br/>Documentation</h4>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Usage documents for created scripts</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Issue detection and resolution</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Non-technical staff education</small>
            </article>        
        </div> 
    </div>
  );
}
function expSA()
{
  return (
    <div className = "expSA">
        <h3>System Administration</h3>
        <div className = "expContent">
            <article className = "expDetails">
                <h4><br/>Users</h4>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Active Directory Setup</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">GPO configuration</small>
            </article>
            <article className = "expDetails">
                <h4><br/>Servers</h4>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Server spec and build</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">ESXi install and configuration</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Windows server install and usage</small>
            </article>
            <article className = "expDetails">
                <h4><br/>Network</h4>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Network hardware install, maintenance</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Subnet creation and deployment</small>
                <h5><BiChevronRight/> Kota Tech</h5>
                <small className = "text-light">Environment troubleshooting</small>
            </article>
        </div>
    </div>
  );
}

export default ExpProps