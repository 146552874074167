import React from 'react'
import './Projects.css'
import ProjectItems from "./ProjectItems.jsx"


const Projects = () => {
  return (
    <section id="Projects">
      <h5>- Learnin -</h5>
      <h2>Stuff I Did While Bored</h2>

      <div className = "portfolioContainer">

        <ProjectItems choice = "gameDev"/>
        <ProjectItems choice = "code"/>
        <ProjectItems choice = "design"/>
        <ProjectItems choice = "model"/>
        <ProjectItems choice = "photoshop"/>
        <ProjectItems choice = ""/>

      </div>
    </section>
  )
}

export default Projects