//rafce
import React from 'react'
import './Footer.css'
import {BiLogoLinkedin} from 'react-icons/bi'
import {TbBrandGithub} from 'react-icons/tb'
import {SiLeetcode} from 'react-icons/si'

const Footer = () => {
  return (
    <footer>
      <div className='homeLink'>
        <a href = "#">Home</a>
      </div>
      <div className='downloadHeader'>
        <li>D o w n l o a d s</li>
      </div>
      <ul className = 'footerLinks'>
        <li><a href="/static/media/resume.dbd39ee94d50af0e343d.pdf" download="Resume">Resume</a></li>
        <li><a href="/static/media/logoPages.8166ad914fcb8841d1f8.pdf" download="logoProject">Graphic Design Example</a></li>
      </ul>

      <div className = 'footerSocialMedia'>
        <a href = 'https://www.linkedin.com/in/ethan-cota-b7228b1b4/' target = "_blank" rel = "noreferrer"><BiLogoLinkedin/></a>
        <a href = 'https://github.com/CotaEthan' target = "_blank" rel = "noreferrer"><TbBrandGithub/></a>
        <a href = "https://leetcode.com/user6430w/" target = '_blank' rel="noreferrer"><SiLeetcode/></a>
      </div>
    </footer>
  )
}

export default Footer