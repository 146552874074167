import React from 'react'
import './Header.css'
import CVA from './CVA'
import HeaderLinks from './HeaderLinks'
import selfPortrait from "../../assets/images/goofy.png"

const Header = () => {
  return (
    <header>
      <div className = "container headerContainer">
        <h5>- Waddap -</h5>
        <h1>Ethan Cota</h1>
        <h5 className = "text-light">A Test Site</h5>

        <div className="selfPortrait">
          <img src={selfPortrait} alt="Hello!"></img>
        </div>
        <a href="#Contact" className="scrolldown">To The End</a>
      </div>
    </header>
  )
}

export default Header