import React from 'react'
import './Contact.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsArrowRightSquareFill} from 'react-icons/bs'

const contact = () => {
  return (
    <section id="Contact">
      <h5>- A Neat -</h5>
      <h2>Email Thing</h2>
      
      <div className = "container contactContainer">

        <div className = "contactBlocks">
          <article className = "contactOption1">
            <h4>Email Me</h4>
            <div className = "icon"><BsArrowRightSquareFill/></div>
          </article>
          <article className = "contactOption2">
            <h4><BsLinkedin/></h4>
            <a href = 'https://www.linkedin.com/in/ethan-cota-b7228b1b4/' className = "btn-other" target = "_blank" rel = "noreferrer">Reach Out</a>
          </article>
        </div>

        <form action = "https://api.web3forms.com/submit" method="POST">
          <input type="hidden" name="access_key" value="3263d2c4-9280-4fe2-bac7-8a9bde1cf5bb"/>

          <input type = "text" name = 'name' placeholder = "Name" required/>
          <input type = "email" name = 'email' placeholder = "Email" required/>
          <textarea name = 'message' placeholder = "Whats up?" rows = '7'  required/>
          <button type="submit" className='btn-other'>Submit</button>
        </form>

      </div>
      
    </section>
  )
}




export default contact