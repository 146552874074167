import React from 'react'
import {useState} from 'react';

function GifAnimate({defaultImage, gifImage, altText}) {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  return (
    <div className="image-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {isMouseOver ? (
        <img src={gifImage} alt={altText} className="gif" />
      ) : (
        <img src={defaultImage} alt={altText} className="main-image" />
      )}
    </div>
  );
}



export default GifAnimate