import React from 'react'

import logoProject from "../../downloads/logoPages.pdf"

import codeSnippet from "../../assets/images/codeSnippet.png"
import designImage from "../../assets/images/design.png"
import model1 from "../../assets/images/3DModel1.png"
import psych from "../../assets/images/psych.png"
import sound from "../../assets/images/sound.png"
import gameCode from "../../assets/images/gameCode.png"

import modelGif from "../../assets/gifs/Models.gif"
import gameGif from "../../assets/gifs/GameDev.gif"
import designGif from "../../assets/gifs/GraphicDesign.gif"

import GifAnimate from "./GifAnimate.jsx"


function ProjectItems(item)
{
    const {choice} = item;
    
    if(choice === "gameDev")
        return gameDev();
    else if(choice === "code")
        return code();
    else if(choice === "design")
        return design();
    else if(choice === "model")
        return model();
    else if(choice === "photoshop")
        return photoshop();
    else
        return audio();
}


function gameDev()
{
  return(
    <article className = "portfolioItem">
        <div className = "portfolioImage">
          <GifAnimate defaultImage={gameCode} gifImage={gameGif} altText="Game Dev GIF" />
        </div>
        <h3>Game Dev</h3>
        <div className = "portfolioLinks">
          <a href = '#Contact' className='btn-primary'>Need Vision?</a>
        </div>
    </article>
  );
}
function code()
{
  return(
    <article className = "portfolioItem">
        <div className = "portfolioImage">
            <img src = {codeSnippet} alt = "Sample Code for a Graph"></img>
        </div>
        <h3>Coding</h3>
        <div className = "portfolioLinks">
          <a href = "https://leetcode.com/user6430w/" className='btn-primary' target = '_blank' rel="noreferrer">LeetCode</a>
        </div>
    </article>
  );
}
function design()
{
  return(
    <article className = "portfolioItem">
        <div className = "portfolioImage">
          <GifAnimate defaultImage={designImage} gifImage={designGif} altText="Gif of a design project" />
        </div>
        <h3>Graphic Design</h3>
        <div className = "portfolioLinks">
          <a href = {logoProject} download="logoProject" className='btn'>Example Project</a>
        </div>
   </article>
  );
}
function model()
{
  return(
    <article className = "portfolioItem">
        <div className = "portfolioImage">
          <GifAnimate defaultImage={model1} gifImage={modelGif} altText="Quick gif of some model ideas" />
        </div>
        <h3>3D Modeling</h3>
        <div className = "portfolioLinks">
          <a href = '#Contact' className='btn-primary'>Want me to craft something?</a>
        </div>
    </article>
  );
}
function photoshop()
{
  return(
    <article className = "portfolioItem">
        <div className = "portfolioImage">
            <img src = {psych} alt = "Purple Infinite Cooridor from Photoshop"></img>
        </div>
        <h3>PhotoShop</h3>
        <div className = "portfolioLinks">
          <a href = '#Contact' className='btn-primary'>Want me to edit something?</a>
        </div>
    </article>
  );
}
function audio()
{
  return(
    <article className = "portfolioItem">
        <div className = "portfolioImage">
            <img src = {sound} alt = "Audio Tracks"></img>
        </div>
        <h3>Audio</h3>
        <div className = "portfolioLinks">
          <a href = '#Contact' className='btn-primary'>Want me to make something?</a>
        </div>
    </article>
  );
}



export default ProjectItems