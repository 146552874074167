import React from 'react';
import Header from './components/Header/Header.jsx'
import About from './components/About/About.jsx'
import Projects from './components/SelfProjects/Projects.jsx'
import Experience from './components/WorkExperience/Experience.jsx'
import Contact from './components/Contact/Contact.jsx'
import Footer from './components/Footer/Footer.jsx'
import Navigation from './components/Navigation/Nav.jsx'

const App = () => {
	return(
		<>
			<div>__________</div>
			<Header />
			<Projects />
			<Contact />
			<Footer />
			
			<Navigation />
		</>
	);
}

export default App;